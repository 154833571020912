import _find from 'lodash/find';
export default (context, type) => {
    if (!context) {
        return () => {};
    }

    let foundKey = _find(context.keys(), key => {
        const renderType = key.replace(/^.*\/[^/]+\/([^/]+)\/index\.js$/, '$1');
        return type === renderType;
    });

    if (foundKey) {
        return context(foundKey);
    }

    console.warn(`App renderer not found for "${type}" in app`);
    return undefined;
};
