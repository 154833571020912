import getRenderFromContext from './getRenderFromContext.js';
import Renders from 'cccisd-table-renders';
import _isUndefined from 'lodash/isUndefined';
import {
    setAppdefEnumFilterSettings,
    queryVariablesHelper,
    rowActionsHelper,
} from 'cccisd-laravel-appdefs';

const rendersContext = require.context(
    'resources/assets/js',
    true,
    /^\.\/renders\/([^/]+\/){1}index\.js$/
);

export default props => {
    let cols = [];
    const {
        content: { graphqlTable },
    } = props;

    const table = graphqlTable.table;
    table.columns.forEach(c => {
        let column = {};
        if ('renderAs' in c) {
            let tmpRender;
            let renderClass = () => {};

            if (c.renderAs.class === 'app') {
                tmpRender = getRenderFromContext(rendersContext, c.renderAs.type);
                if (!_isUndefined(tmpRender)) {
                    renderClass = tmpRender.default;
                }
            } else {
                renderClass = Renders[c.renderAs.class][c.renderAs.type];
            }
            column.render = renderClass(c.renderAs.settings);
            column = Object.assign(column, setAppdefEnumFilterSettings(c, column));
        }
        cols.push(Object.assign(c, column));
    });

    let variables = {};
    if ('requiredVariables' in table) {
        variables = queryVariablesHelper(table.requiredVariables);
    }

    if (table.rowActions) {
        table.rowActions = rowActionsHelper(table.rowActions);
    }

    return {
        columns: cols,
        graphqlVariables: variables,
        ...table,
    };
};
