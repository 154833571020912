import React from 'react';
import PropTypes from 'prop-types';
import InviteButton from './InviteButton';
import getGQLTableProps from '../../helpers/getGQLTableProps.js';
import notification from 'cccisd-notification';
import Table from 'cccisd-graphql-table';
import Modal from 'cccisd-modal';
import Loader from 'cccisd-loader';
import SendMessageNow from './SendMessageNow';
import IconMessage from 'cccisd-icons/envelop3';
import { client as apollo } from 'cccisd-apollo';
import style from './style.css';
import deploymentQuery from './deploymentId.graphql';
import Tabs from 'cccisd-tabs';
import { ResourceCenter } from 'cccisd-laravel-resources';

const Fortress = window.cccisd.fortress;

export default class MyTeachers extends React.Component {
    static propTypes = {
        /**
         * content json for view
         */
        mainTable: PropTypes.object.isRequired,
        pendingTable: PropTypes.object.isRequired,
    };

    state = {
        loading: true,
    };

    componentDidMount = async () => {
        const result = await apollo.query({
            query: deploymentQuery,
            fetchPolicy: 'network-only',
        });
        this.setState({
            loading: false,
            teacherDeployment: result.data.flows.deployment,
        });
    };

    afterFormSubmit = () => {
        // reload data
        this.mainTable.loadData();
        this.bottomTable.loadData();
    };

    pendingTableRender = ({ renderDefault, filters, data }) => {
        const userRole = Fortress.user.acting.data_type;
        if (data.length === 0) {
            const filterArray = Object.values(filters);

            const noFilters = filterArray.every(item => !item.props.value);

            if (noFilters) {
                return null;
            }
        }

        return (
            <div>
                <h3 style={{ fontFamily: "'Barlow Condensed', sans-serif" }}>
                    {userRole === 'admin' ? 'Pending Team Members' : 'Pending Teachers'}
                </h3>
                <div style={{ marginTop: '27px' }}>{renderDefault()}</div>
            </div>
        );
    };

    mainTableRender = ({ renderDefault, filters, data }) => {
        const { teacherDeployment } = this.state;
        const isAdmin = Fortress.user.acting.data_type === 'admin';
        return (
            <div style={{ position: 'relative' }}>
                <h3 style={{ fontFamily: "'Barlow Condensed', sans-serif" }}>
                    {isAdmin ? 'My Team' : 'My Teachers'}
                </h3>
                <div className={style.flexEnd}>
                    {!isAdmin && (
                        <div>
                            <Modal
                                trigger={
                                    <button
                                        type="button"
                                        className={`btn btn-primary ${style.messageButton}`}
                                    >
                                        <IconMessage spaceright /> Send Messages Now
                                    </button>
                                }
                                title="Send Messages Now"
                            >
                                <SendMessageNow
                                    deployment={teacherDeployment}
                                    rtComponent={this.mainTable}
                                    match={this.props.match}
                                    someProp="test"
                                />
                            </Modal>
                        </div>
                    )}
                    <InviteButton afterSubmit={this.afterFormSubmit} />
                </div>
                <div style={{ marginTop: '27px' }}>{renderDefault()}</div>
            </div>
        );
    };

    renderTables = () => {
        const { mainTable, pendingTable } = this.props;
        const isAdmin = Fortress.user.acting.data_type === 'admin';
        return (
            <>
                <div className={`doubleTopContainer ${style.doubleTopContainer}`}>
                    <Table
                        ref={input => {
                            this.mainTable = input;
                        }}
                        {...getGQLTableProps(mainTable)}
                        render={this.mainTableRender}
                        showRowActions={false}
                        rowActions={
                            isAdmin
                                ? []
                                : [
                                      {
                                          name: 'sendNow',
                                          title: 'Send Message',
                                          action: async ({ selectedRows, loadData }) => {
                                              // hidden action -- use the Send Messages Now button
                                              notification('Something is done');
                                          },
                                      },
                                  ]
                        }
                    />

                    <div className={style.bottomTableTopBox}>
                        <Table
                            ref={input => {
                                this.bottomTable = input;
                            }}
                            {...getGQLTableProps(pendingTable)}
                            render={this.pendingTableRender}
                        />
                    </div>
                </div>
            </>
        );
    };

    renderTabs = () => {
        const tables = this.renderTables();
        const isAdmin = Fortress.user.acting.data_type === 'admin';
        const myTitle = isAdmin ? 'My Team' : 'My Teachers';
        const tabList = [
            {
                name: 'My Teachers',
                title: myTitle,
                content: tables,
            },
            {
                name: 'Communications',
                title: 'Communications',
                content: <ResourceCenter handle="Nudges" />,
            },
        ];

        return <Tabs tabList={tabList} />;
    };

    render() {
        const isAdmin = Fortress.user.acting.data_type === 'admin';
        return (
            <Loader loading={this.state.loading} removeChildren>
                <div className={style.tabWrapper}>
                    {isAdmin ? this.renderTables() : this.renderTabs()}
                </div>
            </Loader>
        );
    }
}
