import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Axios from 'cccisd-axios';
import { Formik, Form, Field, CccisdInput, CccisdSelect, CccisdTextarea } from 'cccisd-formik';
import HiddenBlock from 'cccisd-hidden-block';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import MessageViewer from './MessageViewer';
import { reloadCurrentProject } from '../../../reducers/admin.js';
import conditionTranslator from '../../../helpers/messageConditionTranslator.js';
import style from './style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
// const Fortress = window.cccisd && window.cccisd.fortress;

class SendMessageNow extends React.Component {
    static propTypes = {
        rtComponent: PropTypes.object,
        deployment: PropTypes.object,

        // From Redux
        reloadCurrentProject: PropTypes.func,

        // From Modal
        closeModal: PropTypes.func,

        // Required for getDeploymentById to work!
        match: PropTypes.object.isRequired,
    };

    static defaultProps = {
        closeModal: () => {},
    };

    state = {
        loading: true,
        method: undefined,
        recipientCounts: {},
        templateId: -1,
    };

    componentDidMount() {
        if (!this.getSelectedRows().length) {
            this.loadCounts();
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    loadCounts = async () => {
        const tableState = this.props.rtComponent.state;

        const emailList = tableState.data.filter(
            item => item['user.notificationPreference'] === 'Email'
        );
        const textList = tableState.data.filter(
            item => item['user.notificationPreference'] === 'Text'
        );

        const recipientCounts = tableState.data
            ? {
                  all_respondents: {
                      email: emailList.length,
                      sms: textList.length,
                      total: emailList.length + textList.length,
                  },
              }
            : {};
        await this.setState({ loading: false, recipientCounts });
    };

    getSelectedRows = () => {
        return this.props.rtComponent.state.selectedRows;
    };

    getSelectedRowData = () => {
        const tableState = this.props.rtComponent.state;

        return tableState.data.filter(row => {
            return tableState.selectedRows.includes(row['pawn.pawnId']);
        });
    };

    getEligibleRows = values => {
        const selectedRows = this.getSelectedRowData();
        const allRows = this.props.rtComponent.state.data;

        if (this.getSelectedRowData().length === 0) {
            const emailRows = allRows.filter(row => {
                if (row['user.notificationPreference'] === 'Email') {
                    return row[`user.email`];
                }
                return false;
            });

            const textRows = allRows.filter(row => {
                if (row['user.notificationPreference'] === 'Text') {
                    return row[`user.phone`];
                }
                return false;
            });

            return {
                emailRows,
                textRows,
            };
        }

        const emailRows = selectedRows.filter(row => {
            if (row['user.notificationPreference'] === 'Email') {
                return row[`user.email`];
            }
            return false;
        });

        const textRows = selectedRows.filter(row => {
            if (row['user.notificationPreference'] === 'Text') {
                return row[`user.phone`];
            }
            return false;
        });

        return {
            emailRows,
            textRows,
        };
    };

    getPreviewMessageObject = values => {
        const msg = this.props.deployment.deploymentMessageList.find(
            e => e.messageId === values.template
        );
        const msgLabel = msg ? msg.settings.label : '';

        const emailRows = this.getEligibleRows(values).emailRows;
        const textRows = this.getEligibleRows(values).textRows;

        const emailRecipients = emailRows.length ? emailRows.map(e => e[`pawn.pawnId`]) : [];
        const textRecipients = textRows.length ? textRows.map(e => e[`pawn.pawnId`]) : [];

        let emailMsgObj = {
            condition: 'scheduled',
            messageMethod: 'email',
            category: 'preview',

            recipients: emailRecipients,

            settings: {
                label: msgLabel,
                recipientsType: 'respondent',
            },
            messageInfo: {
                subject: values.subject,
                body: values.body,
                outroLines: values.outroLines,
                actionText: values.actionText,
                actionUrl: values.actionUrl,
            },
        };

        let textMsgObj = {
            condition: 'scheduled',
            messageMethod: 'sms',
            category: 'preview',

            recipients: textRecipients,

            settings: {
                label: msgLabel + 'SMS',
                recipientsType: 'respondent',
            },
            messageInfo: {
                body: values.textBody,
            },
        };

        return { emailMsgObj, textMsgObj };
    };

    hasRecipientChain = values => {
        const { settings } = this.props.deployment;

        const chainPath = values.method === 'email' ? 'chainEmailFromPawn' : 'chainPhoneFromPawn';
        const recipientChain = settings && settings[chainPath];

        return !!recipientChain;
    };

    renderSendInfo = (values, selectedTemplate) => {
        const { recipientCounts } = this.state;
        const selectedRows = this.getSelectedRows();

        const eligibleSelected = this.getSelectedRowData().filter(row => {
            if (values.method === 'email') {
                return row[`user.email`];
            }

            if (values.method === 'sms') {
                return row[`user.phone`];
            }

            return false;
        });
        const emailCount = this.getSelectedRowData().filter(row => {
            if (row['user.notificationPreference'] === 'Email') {
                return row[`user.email`];
            }
            return false;
        }).length;

        const smsCount = this.getSelectedRowData().filter(row => {
            if (row['user.notificationPreference'] === 'Text') {
                return row[`user.phone`];
            }
            return false;
        }).length;

        const hasRecipientChain = this.hasRecipientChain(values);
        const allIneligible =
            selectedRows.length && !hasRecipientChain ? eligibleSelected.length === 0 : false;
        const recipientCountData = recipientCounts[selectedTemplate.condition] || {};
        const recipientCount = recipientCountData.total;
        const recipientTotal = recipientCountData.total;
        const conditionName = conditionTranslator(selectedTemplate.condition);
        let sendInfo = selectedRows.length
            ? `Send to the ${eligibleSelected.length} ${
                  eligibleSelected.length !== selectedRows.length ? 'eligible' : ''
              } selected.${
                  selectedRows.length === eligibleSelected.length
                      ? ` (Email(s): ${emailCount} | SMS Message(s): ${smsCount})`
                      : ` (${selectedRows.length - eligibleSelected.length} selected without ${
                            values.method === 'email' ? 'an email address' : 'a phone number'
                        }.)`
              }`
            : `Send to ALL (${recipientCount}) ${
                  recipientTotal > recipientCount ? 'eligible' : ''
              } teachers. ${
                  selectedTemplate.condition === 'all_respondents'
                      ? `(Email(s): ${recipientCountData.email} | SMS Message(s): ${recipientCountData.sms})`
                      : `meeting condition "${conditionName}"`
              }${
                  recipientTotal === recipientCount
                      ? ''
                      : ` (${recipientTotal - recipientCount} other${
                            recipientTotal - recipientCount > 1 ? 's' : ''
                        } without ${
                            values.method === 'email' ? 'an email address' : 'a phone number'
                        }).`
              }`;
        if (allIneligible) {
            return (
                <div className={`alert alert-danger ${style.sendInfo}`} role="alert">
                    All selected ({selectedRows.length}) have an empty{' '}
                    {values.method === 'email' ? 'email address' : 'phone number'}.
                </div>
            );
        }
        if (!selectedRows.length && recipientCount === 0) {
            if (recipientTotal === 0) {
                return (
                    <div className={`alert alert-danger ${style.sendInfo}`} role="alert">
                        {`No respondents match the criteria "${conditionName}".`}
                    </div>
                );
            }
            if (!hasRecipientChain) {
                return (
                    <div className={`alert alert-danger ${style.sendInfo}`} role="alert">
                        {`All respondents (${recipientTotal}) ${
                            selectedTemplate.condition === 'all_respondents'
                                ? ''
                                : `meeting condition "${conditionName}"`
                        }
                     have an empty ${
                         values.method === 'email' ? 'email address' : 'phone number'
                     }.`}
                    </div>
                );
            }
        }
        if (hasRecipientChain) {
            sendInfo = selectedRows.length
                ? `Attempt to send to the ${selectedRows.length} selected respondents.`
                : `Attempt to send to all ${recipientTotal} respondents meeting condition "${conditionName}".`;
        }

        return (
            <div className="alert alert-warning" role="alert">
                {sendInfo}
            </div>
        );
    };

    hasEligibleRecipients = values => {
        const { deployment } = this.props;
        const { recipientCounts } = this.state;
        const { method, template } = values;
        const selectedRows = this.getSelectedRows();
        const selectedTemplate = deployment.deploymentMessageList.find(
            e => e.messageId === template
        );
        const conditionCounts = recipientCounts[selectedTemplate.condition] || {};
        if (this.hasRecipientChain(values)) {
            return selectedRows.length || conditionCounts.total;
        }
        if (selectedRows.length) {
            const eligibleSelected = this.getSelectedRowData().filter(row => {
                if (method === 'email') {
                    return row[`user.email`];
                }
                if (method === 'sms') {
                    return row[`user.phone`];
                }
                return false;
            });
            return eligibleSelected.length > 0;
        }
        return conditionCounts[method] > 0;
    };

    /* /////////////////////////////////////////////////////////////////////////
    // FORMIK METHODS /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    onSubmit = async values => {
        const msgObjs = this.getPreviewMessageObject(values);
        const emailObj = msgObjs.emailMsgObj;
        const textObj = msgObjs.textMsgObj;
        delete emailObj.category;
        delete textObj.category;

        emailObj.scheduleTime = moment
            .utc()
            .add(5, 'seconds')
            .format();

        textObj.scheduleTime = moment
            .utc()
            .add(5, 'seconds')
            .format();

        let success;

        if (textObj.recipients.length > 0 && textObj.messageInfo.body !== '') {
            const textResponse = await Axios.post(
                Boilerplate.route(`api.assignmentDeploymentMessage.store`, {
                    deploymentId: this.props.deployment.deploymentId,
                }),
                textObj
            );
            if (textResponse.data.status === 'success') {
                success = textResponse;
            }
        }

        if (emailObj.recipients.length > 0) {
            const emailResponse = await Axios.post(
                Boilerplate.route(`api.assignmentDeploymentMessage.store`, {
                    deploymentId: this.props.deployment.deploymentId,
                }),
                emailObj
            );

            if (emailResponse.data.status === 'success') {
                success = emailResponse;
            }
        }

        if (success.data.status === 'success') {
            this.props.closeModal();
            this.props.reloadCurrentProject();

            return notification({
                type: 'success',
                message: `Successfully scheduled respondent message "${emailObj.settings.label}".`,
            });
        }

        notification({
            type: 'danger',
            message: `Failed to schedule respondent message "${emailObj.settings.label}".`,
            duration: 5000,
        });
    };

    validate = values => {
        let errors = {};

        if (!this.hasEligibleRecipients(values)) {
            errors._selected = 'All selected are invalid';
        }

        if (values.actionText && !values.actionUrl) {
            errors.actionUrl = 'You must supply a Button URL if Button Text is provided';
        }
        if (!values.actionText && values.actionUrl) {
            errors.actionText = 'You must supply Button Text if a Button URL is provided';
        }
        if (!values.subject) {
            errors.subject = 'You must supply a Subject';
        }
        if (!values.body && !values.outroLines) {
            errors.body = 'You must supply either Body (upper) or Body (lower)';
            errors.outroLines = 'You must supply either Body (upper) or Body (lower)';
        }
        if (!values.textBody) {
            errors.textBody = 'You must supply a Text Message Body';
        }
        if (errors.textBody) {
            this.showTextBlock();
        }
        if (errors.actionText || errors.subject || (errors.body && errors.outroLines)) {
            this.showEmailBLock();
        }
        return errors;
    };

    showEmailBLock = () => {
        setTimeout(() => {
            this.editEmailMessageBlock.setShow(true);
        }, 300);
    };

    showTextBlock = () => {
        setTimeout(() => {
            this.editTextMessageBlock.setShow(true);
        }, 300);
    };
    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    render() {
        const { deployment } = this.props;
        const { loading, templateId } = this.state;

        if (loading) {
            return <Loader loading />;
        }

        const respondentConditions = [
            'all_respondents',
            'complete',
            'incomplete',
            'incomplete_or_not_started',
            'not_started',
            'on_respondent_complete',
        ];

        const emailTemplates = deployment.deploymentMessageList.filter(msg => {
            return (
                msg.category === 'TEMPLATE' &&
                respondentConditions.includes(msg.condition) &&
                msg.messageMethod === 'email'
            );
        });
        const smsTemplates = deployment.deploymentMessageList.filter(msg => {
            return (
                msg.category === 'TEMPLATE' &&
                respondentConditions.includes(msg.condition) &&
                msg.messageMethod === 'sms'
            );
        });
        const templateOptions = emailTemplates.map(item => {
            return {
                label: item.settings.label,
                value: item.messageId,
            };
        });

        let selectedTemplate = emailTemplates.find(e => e.messageId === templateId);

        if (!selectedTemplate && emailTemplates.length) {
            selectedTemplate = emailTemplates[0];
        }
        const match = smsTemplates.find(
            item => item.settings.label === selectedTemplate.settings.label + 'SMS'
        );
        let initialValues = { method: 'email' };
        if (selectedTemplate) {
            initialValues = Object.assign(initialValues, {
                template: selectedTemplate.messageId,
                subject: selectedTemplate.messageInfo.subject || '',
                body: selectedTemplate.messageInfo.body || '',
                outroLines: selectedTemplate.messageInfo.outroLines || '',
                actionText: selectedTemplate.messageInfo.actionText || '',
                actionUrl: selectedTemplate.messageInfo.actionUrl || '',
                method: selectedTemplate.messageMethod,
                textBody: match ? match.messageInfo.body : '',
            });
        } else {
            const messageLink = this.props.match.url.replace('/track/', '/messaging/');
            return (
                <div className="alert alert-danger" role="alert">
                    You must first create a template message in the{' '}
                    {
                        <Link className="alert-link" to={messageLink}>
                            Messages
                        </Link>
                    }{' '}
                    area.
                </div>
            );
        }

        const initialMethod = initialValues.method;
        if (this.state.method) {
            if (initialMethod !== this.state.method) {
                initialValues.body = '';
            }
            initialValues.method = this.state.method;
        }

        const sendInfo = this.renderSendInfo(initialValues, selectedTemplate);

        return (
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    render={props => (
                        <Form>
                            {sendInfo}
                            <Field
                                name="template"
                                component={CccisdSelect}
                                label="Template (by Label): *"
                                options={templateOptions}
                                onChange={e => {
                                    props.handleChange(e);
                                    this.setState({
                                        templateId: Number.parseInt(e.target.value, 10),
                                    });
                                }}
                            />

                            <div className="alert alert-info">
                                Note: If you edit the content in the email you must also edit the
                                content in the text message.
                            </div>

                            <HiddenBlock
                                title="Edit Email"
                                ref={e => {
                                    this.editEmailMessageBlock = e;
                                }}
                            >
                                <Field name="subject" component={CccisdInput} label="Subject: *" />
                                <div className="alert" style={{ backgroundColor: '#D6D8D9' }}>
                                    Note: Phrases within “%%” (i.e. %firstName%) automatically pull
                                    in information specific to each recipient and should not be
                                    edited. Doing so will change the message for all recipients, not
                                    just one.
                                </div>
                                <Field
                                    name="body"
                                    component={CccisdTextarea}
                                    label="Body (upper):"
                                />

                                <Field
                                    name="actionText"
                                    component={CccisdInput}
                                    label="Button Text:"
                                />
                                <Field
                                    name="actionUrl"
                                    component={CccisdInput}
                                    label="Button URL:"
                                    disabled
                                />
                                <Field
                                    name="outroLines"
                                    component={CccisdTextarea}
                                    label="Body (lower):"
                                />
                            </HiddenBlock>
                            <HiddenBlock
                                title="Edit Text Message"
                                ref={e => {
                                    this.editTextMessageBlock = e;
                                }}
                            >
                                <Field
                                    name="textBody"
                                    component={CccisdTextarea}
                                    label="Text Message Body:"
                                />
                            </HiddenBlock>

                            <Modal
                                trigger={
                                    <button
                                        type="button"
                                        className={`btn btn-default ${style.button}`}
                                    >
                                        Preview
                                    </button>
                                }
                                title="Previewing message"
                                size={
                                    props.values.method &&
                                    props.values.method.toUpperCase() === 'EMAIL'
                                        ? 'large'
                                        : 'small'
                                }
                            >
                                <MessageViewer
                                    messageObject={this.getPreviewMessageObject(props.values)}
                                />
                            </Modal>
                            <button
                                className={`btn btn-primary ${style.button}`}
                                type="submit"
                                disabled={props.isSubmitting}
                            >
                                Send Message (Right Now)
                            </button>
                        </Form>
                    )}
                />
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps, { reloadCurrentProject })(SendMessageNow);
