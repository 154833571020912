import { createAction, handleActions } from 'redux-actions';
import { client as apollo } from 'cccisd-apollo';
import projectListQuery from './projectListQuery.graphql';
import designTabQuery from './designTabQuery.graphql';
import axios from 'cccisd-axios';
import _difference from 'lodash/difference';
import _union from 'lodash/union';
import _xor from 'lodash/xor';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';
import {
    getCurrentProjectAssignments,
    getCurrentAssignmentDeployments,
    getCurrentProject,
} from '../selectors/admin.js';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

export const initialState = {
    projectList: [],
    currentProjectId: 0,
    currentAssignmentId: 0,
    currentDeploymentId: 0,
    selectedDeploymentIds: [],
    currentDeploymentIds: [],
    deploymentDropdownVisible: false,
    currentSurveyId: 0,
    currentSurveyHandle: '0',
    currentSurveyHandles: [],
    surveyDropdownVisible: false,
    expandedDesignAssignmentIds: [],
    expandedDeployAssignmentIds: [],
    showArchivedAssignments: false,
    showArchivedSurveys: [],
    currentSurveyListHandles: {},
    designFilter: '',
    deployFilter: '',
    devTags: [],
};

// Actions
const SET_PROJECT_LIST = 'assignment/admin/SET_PROJECT_LIST';
const SET_PROJECT_FLOWS = 'assignment/admin/SET_PROJECT_FLOWS';
const SET_CURRENT_PROJECT_ID = 'assignment/admin/SET_CURRENT_PROJECT_ID';
const SET_CURRENT_ASSIGNMENT_ID = 'assignment/admin/SET_CURRENT_ASSIGNMENT_ID';
const SET_CURRENT_ASSIGNMENT_ID_ONLY = 'assignment/admin/SET_CURRENT_ASSIGNMENT_ID_ONLY';
const SET_CURRENT_DEPLOYMENT_ID = 'assignment/admin/SET_CURRENT_DEPLOYMENT_ID';
const SET_SELECTED_DEPLOYMENT_IDS = 'assignment/admin/SET_SELECTED_DEPLOYMENT_IDS';
const SET_CURRENT_DEPLOYMENT_IDS = 'assignment/admin/SET_CURRENT_DEPLOYMENT_IDS';
const SET_CURRENT_SURVEY_ID = 'assignment/admin/SET_CURRENT_SURVEY_ID';
const SET_CURRENT_SURVEY_HANDLE = 'assignment/admin/SET_CURRENT_SURVEY_HANDLE';
const SET_CURRENT_SURVEY_HANDLES = 'assignment/admin/SET_CURRENT_SURVEY_HANDLES';
const SET_EXPANDED_ALL_DESIGN_ASSIGNMENTS = 'assignment/admin/SET_EXPANDED_ALL_DESIGN_ASSIGNMENTS';
const TOGGLE_COLLAPSED_DESIGN_ASSIGNMENT = 'assignment/admin/TOGGLE_COLLAPSED_DESIGN_ASSIGNMENT';
const SET_EXPANDED_ALL_DEPLOY_ASSIGNMENTS = 'assignment/admin/SET_EXPANDED_ALL_DEPLOY_ASSIGNMENTS';
const TOGGLE_COLLAPSED_DEPLOY_ASSIGNMENT = 'assignment/admin/TOGGLE_COLLAPSED_DEPLOY_ASSIGNMENT';
const TOGGLE_ARCHIVED_ASSIGNMENTS = 'assignment/admin/TOGGLE_ARCHIVED_ASSIGNMENTS';
const TOGGLE_ARCHIVED_SURVEYS = 'assignment/admin/TOGGLE_ARCHIVED_SURVEYS';
const MOVE_SURVEY = 'assignment/admin/MOVE_SURVEY';
const SET_CURRENT_SURVEY_LIST_HANDLE = 'assignment/admin/SET_CURRENT_SURVEY_LIST_HANDLE';
const SET_WAVE_OPEN_STATUS = 'assignment/admin/SET_WAVE_OPEN_STATUS';
const CHANGE_DESIGN_FILTER = 'assignment/admin/CHANGE_DESIGN_FILTER';
const CHANGE_DEPLOY_FILTER = 'assignment/admin/CHANGE_DEPLOY_FILTER';
const SET_DEV_TAGS = 'assignment/admin/SET_DEV_TAGS';

// Action Creators
export const setProjectList = createAction(SET_PROJECT_LIST);
export const setCurrentProjectId = createAction(SET_CURRENT_PROJECT_ID);
export const setCurrentAssignmentId = createAction(SET_CURRENT_ASSIGNMENT_ID);
export const setCurrentAssignmentIdOnly = createAction(SET_CURRENT_ASSIGNMENT_ID_ONLY);
export const setCurrentDeploymentId = createAction(SET_CURRENT_DEPLOYMENT_ID);
export const setSelectedDeploymentIds = createAction(SET_SELECTED_DEPLOYMENT_IDS);
export const setCurrentDeploymentIds = createAction(SET_CURRENT_DEPLOYMENT_IDS);
export const setCurrentSurveyId = createAction(SET_CURRENT_SURVEY_ID);
export const setCurrentSurveyHandle = createAction(SET_CURRENT_SURVEY_HANDLE);
export const setCurrentSurveyHandles = createAction(SET_CURRENT_SURVEY_HANDLES);
export const setProjectFlows = createAction(SET_PROJECT_FLOWS);
export const setExpandedAllDesignAssignments = createAction(SET_EXPANDED_ALL_DESIGN_ASSIGNMENTS);
export const toggleCollapsedDesignAssignment = createAction(TOGGLE_COLLAPSED_DESIGN_ASSIGNMENT);
export const setExpandedAllDeployAssignments = createAction(SET_EXPANDED_ALL_DEPLOY_ASSIGNMENTS);
export const toggleCollapsedDeployAssignment = createAction(TOGGLE_COLLAPSED_DEPLOY_ASSIGNMENT);
export const toggleArchivedAssignments = createAction(TOGGLE_ARCHIVED_ASSIGNMENTS);
export const toggleArchivedSurveys = createAction(TOGGLE_ARCHIVED_SURVEYS);
export const moveSurvey = createAction(MOVE_SURVEY);
export const setCurrentSurveyListHandle = createAction(SET_CURRENT_SURVEY_LIST_HANDLE);
export const setWaveOpenStatus = createAction(SET_WAVE_OPEN_STATUS);
export const changeDesignFilter = createAction(CHANGE_DESIGN_FILTER);
export const changeDeployFilter = createAction(CHANGE_DEPLOY_FILTER);
export const setDevTags = createAction(SET_DEV_TAGS);

export const loadProjectList = () => {
    return async (dispatch, getState) => {
        // Don't load projects twice
        const state = getState().assignment.admin;
        if (state.projectList.length > 0) {
            return;
        }

        if (Fortress.user.acting.data_type === 'guest') {
            window.location = Boilerplate.url('account/login');
            return;
        }

        let result = null;
        try {
            result = await apollo.query({
                query: projectListQuery,
                fetchPolicy: 'network-only',
            });
        } catch (e) {
            return;
        }

        const projectList = result.data.groups.questprojectList.map(project => ({
            ...project.group,
            fields: project.fields,
        }));
        dispatch(setProjectList(projectList));
    };
};

export const loadProject = groupId => {
    return async (dispatch, getState) => {
        if (Fortress.user.acting.data_type === 'guest') {
            window.location = Boilerplate.url('account/login');
            return;
        }

        let result = null;
        try {
            result = await apollo.query({
                query: designTabQuery,
                variables: { groupId },
                fetchPolicy: 'network-only',
            });
        } catch (e) {
            return;
        }

        dispatch(setProjectFlows({ groupId, flows: result.data.flows }));
        dispatch(setCurrentProjectId(groupId));
    };
};

export const loadDevTags = () => {
    return async (dispatch, getState) => {
        const response = await axios.get(
            Boilerplate.route('poly.api.v1.tag.tagNames') + '?scope=developer'
        );
        dispatch(setDevTags(response.data));
    };
};

export const reloadCurrentProject = () => {
    return async (dispatch, getState) => {
        const { currentProjectId } = getState().assignment.admin;
        await dispatch(loadProject(currentProjectId));
    };
};

export const createQuest = values => {
    return async (dispatch, getState) => {
        const { currentProjectId } = getState().assignment.admin;

        const response = await axios.post(Boilerplate.route('api.assignment.store'), {
            group: currentProjectId,
            label: values.title,
            description: values.subtitle,
            settings: _omit(values, ['title', 'subtitle']),
        });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateQuest = (assignmentId, values) => {
    return async (dispatch, getState) => {
        const response = await axios.post(Boilerplate.route('api.assignment.update'), {
            assignmentId,
            label: values.title,
            description: values.subtitle,
            handle: values.handle || `assignment${assignmentId}`,
            settings: _omit(values, ['title', 'subtitle', 'handle']),
        });

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateQuestSettings = (assignmentId, settings) => {
    return async (dispatch, getState) => {
        const response = await axios.post(Boilerplate.route(`api.assignment.update`), {
            assignmentId,
            settings,
        });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const archiveQuest = assignmentId => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route('api.assignment.archive', { assignmentId })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const unarchiveQuest = assignmentId => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route('api.assignment.unarchive', { assignmentId })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const deleteQuest = assignmentId => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route(`api.assignment.destroy`, { assignmentId })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const createSurvey = (surveyListId, values) => {
    return async (dispatch, getState) => {
        const response = await axios.post(Boilerplate.route('api.assignmentSurvey.store'), {
            label: values.title,
            description: values.subtitle,
            assignmentSurveylistId: surveyListId,
            settings: _omit(values, ['title', 'subtitle']),
        });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateSurvey = (surveyId, values) => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route('api.assignmentSurvey.update', { surveyId }),
            {
                label: values.title,
                description: values.subtitle,
                settings: _omit(values, ['title', 'subtitle']),
            }
        );

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateSurveySettings = (surveyId, settings) => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route(`api.assignmentSurvey.update`, { surveyId }),
            { settings }
        );

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const archiveSurvey = surveyId => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route('api.assignmentSurvey.archive', { surveyId })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const unarchiveSurvey = surveyId => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route(`api.assignmentSurvey.unarchive`, { surveyId })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const deleteSurvey = surveyId => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route(`api.assignmentSurvey.destroy`, { surveyId })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const moveSurveyAndSave = ({ assignmentId, sourceIndex, destinationIndex }) => {
    return async (dispatch, getState) => {
        const getSurveylistList = () =>
            getCurrentProject(getState().assignment.admin).flows.assignmentList.find(
                item => item.assignmentId === assignmentId
            ).currentSurveylistList.surveyList;

        const prevPositions = getSurveylistList().reduce(
            (res, item) => ({ ...res, [item.surveyId]: item.position }),
            {}
        );

        dispatch(moveSurvey({ assignmentId, sourceIndex, destinationIndex }));

        const updatedSurveys = getSurveylistList().filter(
            item => prevPositions[item.surveyId] !== item.position
        );

        // TODO: send one request instead of many. For now, do one-at-a-time to
        // ensure we don't 500 with deadlocks
        for (const survey of updatedSurveys) {
            await axios.put(
                Boilerplate.route(`api.assignmentSurvey.update`, { surveyId: survey.surveyId }),
                { ordinalPosition: survey.position }
            );
        }

        return true;
    };
};

export const deleteMessage = (deploymentId, deploymentMessageId) => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route(`api.assignmentDeploymentMessage.destroy`, {
                deploymentId,
                deploymentMessageId,
            })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const unscheduleMessage = (deploymentId, deploymentMessageId) => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route(`api.assignmentDeploymentMessage.destroy`, {
                deploymentId,
                deploymentMessageId,
            })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const createFlow = data => {
    return async (dispatch, getState) => {
        const response = await axios.post(Boilerplate.route('flow.store'), data);

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const createWave = (assignmentId, values) => {
    return async (dispatch, getState) => {
        const response = await axios.post(Boilerplate.route('api.assignmentDeployment.store'), {
            ...values,
            assignmentId,
        });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateWave = (deploymentId, values) => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route('api.assignmentDeployment.update', { deploymentId }),
            values
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return response;
    };
};

export const removeWave = deploymentId => {
    return async (dispatch, getState) => {
        await axios.put(Boilerplate.route('api.assignmentDeployment.destroy', { deploymentId }));
        await dispatch(reloadCurrentProject());
    };
};

export const setWaveOpenStatusAndSave = ({ deploymentId, isOpen }) => {
    return async (dispatch, getState) => {
        dispatch(setWaveOpenStatus({ deploymentId, isOpen }));

        await axios.put(Boilerplate.route('api.assignmentDeployment.update', { deploymentId }), {
            isOpen,
        });
    };
};

// reducer
export default handleActions(
    {
        [SET_PROJECT_LIST]: (state, action) => ({
            ...state,
            projectList: action.payload,
        }),
        [SET_CURRENT_PROJECT_ID]: (state, action) => {
            const nextState = {
                ...state,
                currentProjectId: action.payload,
            };

            const { currentAssignmentId } = state;
            const activeAssignmentsIds = getCurrentProjectAssignments(nextState)
                .filter(item => !item.archived)
                .map(item => item.assignmentId);

            if (activeAssignmentsIds.includes(currentAssignmentId)) {
                return nextState;
            }

            nextState.currentAssignmentId = activeAssignmentsIds[0] || 0;

            const assignmentDeployments = getCurrentAssignmentDeployments(nextState).filter(
                item => !item.archived
            );
            const assignmentDeploymentsIds = assignmentDeployments.map(item => item.deploymentId);

            const currentDeploymentId = assignmentDeploymentsIds[0] || 0;

            return {
                ...nextState,
                currentDeploymentId,
                currentDeploymentIds: currentDeploymentId ? [currentDeploymentId] : [],
                selectedDeploymentIds: currentDeploymentId ? [currentDeploymentId] : [],
                currentSurveyHandles: currentDeploymentId
                    ? assignmentDeployments.find(d => d.deploymentId === currentDeploymentId)
                          .settings.options
                    : [],
            };
        },
        [SET_CURRENT_ASSIGNMENT_ID]: (state, action) => ({
            ...state,
            currentAssignmentId: parseInt(action.payload, 10),
            currentDeploymentId: initialState.currentDeploymentId,
            currentDeploymentIds: [],
            selectedDeploymentIds: [],
            currentSurveyHandles: [],
        }),
        [SET_CURRENT_ASSIGNMENT_ID_ONLY]: (state, action) => ({
            ...state,
            currentAssignmentId: parseInt(action.payload, 10),
        }),
        [SET_CURRENT_DEPLOYMENT_ID]: (state, action) => ({
            ...state,
            currentDeploymentId: parseInt(action.payload, 10),
            currentDeploymentIds: action.payload !== 0 ? [parseInt(action.payload, 10)] : [],
            selectedDeploymentIds: action.payload !== 0 ? [parseInt(action.payload, 10)] : [],
        }),
        [SET_SELECTED_DEPLOYMENT_IDS]: (state, action) => ({
            ...state,
            selectedDeploymentIds: action.payload,
        }),
        [SET_CURRENT_DEPLOYMENT_IDS]: (state, action) => ({
            ...state,
            currentDeploymentId: action.payload.length
                ? parseInt(action.payload[0], 10)
                : initialState.currentDeploymentId,
            currentDeploymentIds: action.payload,
        }),
        [SET_CURRENT_SURVEY_ID]: (state, action) => ({
            ...state,
            currentSurveyId: parseInt(action.payload, 10),
        }),
        [SET_CURRENT_SURVEY_HANDLE]: (state, action) => ({
            ...state,
            currentSurveyHandle: action.payload,
        }),
        [SET_CURRENT_SURVEY_HANDLES]: (state, action) => {
            return {
                ...state,
                currentSurveyHandles: action.payload,
            };
        },
        [SET_PROJECT_FLOWS]: (state, action) => ({
            ...state,
            projectList: state.projectList.map(project =>
                project.groupId === action.payload.groupId
                    ? { ...project, flows: action.payload.flows }
                    : project
            ),
        }),
        [SET_EXPANDED_ALL_DESIGN_ASSIGNMENTS]: (state, action) => {
            const expand = action.payload;
            const currentProjectAssignmentIds = getCurrentProjectAssignments(state).map(
                obj => obj.assignmentId
            );

            const expandedDesignAssignmentIds = expand
                ? _union(state.expandedDesignAssignmentIds, currentProjectAssignmentIds)
                : _difference(state.expandedDesignAssignmentIds, currentProjectAssignmentIds);

            return {
                ...state,
                expandedDesignAssignmentIds,
            };
        },
        [TOGGLE_COLLAPSED_DESIGN_ASSIGNMENT]: (state, action) => ({
            ...state,
            expandedDesignAssignmentIds: _xor(state.expandedDesignAssignmentIds, [action.payload]),
        }),
        [SET_EXPANDED_ALL_DEPLOY_ASSIGNMENTS]: (state, action) => {
            const expand = action.payload;
            const currentProjectAssignmentIds = getCurrentProjectAssignments(state).map(
                obj => obj.assignmentId
            );

            const expandedDeployAssignmentIds = expand
                ? _union(state.expandedDeployAssignmentIds, currentProjectAssignmentIds)
                : _difference(state.expandedDeployAssignmentIds, currentProjectAssignmentIds);

            return {
                ...state,
                expandedDeployAssignmentIds,
            };
        },
        [TOGGLE_COLLAPSED_DEPLOY_ASSIGNMENT]: (state, action) => ({
            ...state,
            expandedDeployAssignmentIds: _xor(state.expandedDeployAssignmentIds, [action.payload]),
        }),
        [TOGGLE_ARCHIVED_ASSIGNMENTS]: (state, action) => ({
            ...state,
            showArchivedAssignments: !state.showArchivedAssignments,
        }),
        [TOGGLE_ARCHIVED_SURVEYS]: (state, action) => ({
            ...state,
            showArchivedSurveys: _xor(state.showArchivedSurveys, [action.payload]),
        }),
        [SET_CURRENT_SURVEY_LIST_HANDLE]: (state, action) => ({
            ...state,
            currentSurveyListHandles: {
                ...state.currentSurveyListHandles,
                [action.payload.assignmentId]: action.payload.handle,
            },
        }),
        [MOVE_SURVEY]: (state, action) => {
            const { assignmentId, sourceIndex, destinationIndex } = action.payload;
            const { currentProjectId, currentSurveyListHandles } = state;

            try {
                const sortByPosition = (a, b) => a.position - b.position;
                const projectList = _cloneDeep(state.projectList);
                const currentProject = projectList.find(item => item.groupId === currentProjectId);
                const currentAssignment = currentProject.flows.assignmentList.find(
                    item => item.assignmentId === assignmentId
                );
                const currentSurveylistList = currentSurveyListHandles[assignmentId]
                    ? currentAssignment.surveylistList.find(
                          item => item.surveylistHandle === currentSurveyListHandles[assignmentId]
                      )
                    : currentAssignment.surveylistList[0];
                const surveyList = currentSurveylistList.surveyList.sort(sortByPosition);
                const activeSurveyList = surveyList.filter(item => !item.archived);
                const sourcePosition = activeSurveyList[sourceIndex].position;
                const destinationPosition = activeSurveyList[destinationIndex].position;

                currentSurveylistList.surveyList = surveyList
                    .map(item => {
                        let position = item.position;
                        if (item.position === sourcePosition) {
                            position = destinationPosition;
                        } else if (
                            item.position > sourcePosition &&
                            item.position <= destinationPosition
                        ) {
                            position = item.position - 1;
                        } else if (
                            item.position < sourcePosition &&
                            item.position >= destinationPosition
                        ) {
                            position = item.position + 1;
                        }

                        return {
                            ...item,
                            position,
                        };
                    })
                    .sort(sortByPosition);

                return {
                    ...state,
                    projectList,
                };
            } catch (e) {
                return state;
            }
        },
        [SET_WAVE_OPEN_STATUS]: (state, action) => {
            const { deploymentId, isOpen } = action.payload;
            const { currentProjectId } = state;

            try {
                const projectList = _cloneDeep(state.projectList);
                const currentProject = projectList.find(item => item.groupId === currentProjectId);
                const deployments = currentProject.flows.assignmentList.reduce(
                    (res, assignment) => [...res, ...assignment.deploymentList],
                    []
                );
                const currentDeployment = deployments.find(
                    item => item.deploymentId === deploymentId
                );
                currentDeployment.isOpen = isOpen;

                return {
                    ...state,
                    projectList,
                };
            } catch (e) {
                return state;
            }
        },
        [CHANGE_DESIGN_FILTER]: (state, action) => ({
            ...state,
            designFilter: action.payload,
        }),
        [CHANGE_DEPLOY_FILTER]: (state, action) => ({
            ...state,
            deployFilter: action.payload,
        }),
        [SET_DEV_TAGS]: (state, action) => ({
            ...state,
            devTags: action.payload,
        }),
    },
    initialState
);
