import React from 'react';
import { Formik, Form, Field, CccisdInput, CccisdSelect } from 'cccisd-formik';
import style from './style.css';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';

const roles = [
    { value: null, label: '--Please Select a Role--' },
    { value: 'teacher', label: 'Teacher' },
    { value: 'coach', label: 'Coach' },
];
const Fortress = window.cccisd.fortress;

export default class InviteForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func,
    };

    validate = values => {
        const errors = {};
        const userRole = Fortress.user.acting.data_type;
        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!isEmail(values.email.trim())) {
            errors.email = 'Please enter a valid email';
        }

        if (!values.first_name) {
            errors.first_name = 'First name is required';
        }
        if (!values.last_name) {
            errors.last_name = 'Last name is required';
        }
        if (userRole === 'admin') {
            if (!values.role || values.role === '--Please Select a Role--') {
                errors.role = 'Role is required';
            }
        }
        return errors;
    };

    render() {
        const userRole = Fortress.user.acting.data_type;
        return (
            <div className={style.registrationForm}>
                <Formik
                    validate={this.validate}
                    onSubmit={this.props.handleSubmit}
                    render={() => (
                        <Form>
                            <Field name="email" component={CccisdInput} label="Email*" />
                            <Field name="first_name" component={CccisdInput} label="First Name*" />
                            <Field name="last_name" component={CccisdInput} label="Last Name*" />
                            {userRole === 'admin' && (
                                <Field
                                    name="role"
                                    component={CccisdSelect}
                                    options={roles}
                                    label="Role*"
                                />
                            )}
                            <div className={style.submitButton}>
                                <button className="btn btn-primary" type="submit">
                                    Send Invitation
                                </button>
                            </div>
                        </Form>
                    )}
                />
            </div>
        );
    }
}
