import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _map from 'lodash/map';
// import { Html } from 'cccisd-wysiwyg';
import conditionTranslator from '../../../../helpers/messageConditionTranslator.js';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';

export default class MessageViewer extends React.Component {
    static propTypes = {
        messageObject: PropTypes.object.isRequired,
        sendDateStr: PropTypes.string,
    };

    static defaultProps = {
        sendDateStr: '',
    };

    showLines = string => {
        if (!string) {
            return null;
        }
        const lines = string.split('\n\n');
        return lines.map((e, i) => {
            const br = i === lines.length - 1 ? null : <br />;
            return (
                <React.Fragment key={i}>
                    <Html content={`<div>${e}</div>`} />
                    {br}
                </React.Fragment>
            );
        });
    };

    // info area
    _renderInfoArea = method => {
        const {
            messageObject: { emailMsgObj, textMsgObj },
        } = this.props;
        const isEmail = method === 'email';

        const condition = isEmail
            ? conditionTranslator(emailMsgObj.condition)
            : conditionTranslator(textMsgObj.condition);

        return (
            <div className={style.infoArea}>
                {!isEmail && this._renderTags('sms')}

                <div className={style.infoLine}>
                    <label>Label:</label>
                    <p>{isEmail ? emailMsgObj.settings.label : textMsgObj.settings.label}</p>
                </div>
                {condition.toUpperCase() !== 'TEMPLATE' && (
                    <div className={style.infoLine}>
                        <label>Condition:</label>
                        <p>{condition}</p>
                    </div>
                )}
                <div className={style.infoLine}>
                    <label>Message Type:</label>
                    <p>{method === 'email' ? 'Email' : 'SMS/Text'}</p>
                </div>

                <div className={style.flexSpace} />

                {isEmail && this._renderTags('email')}
            </div>
        );
    };

    // table tags
    _renderTags = method => {
        const {
            messageObject: { emailMsgObj, textMsgObj },
        } = this.props;
        const isEmail = method === 'email';

        const ucCategory = isEmail
            ? emailMsgObj.category.toUpperCase()
            : textMsgObj.category.toUpperCase();
        return (
            <>
                {['DRAFT', 'TEMPLATE', 'PREVIEW'].includes(ucCategory) && (
                    <div className={classnames('label label-default', style.noteLabel)}>
                        <span>
                            {isEmail ? 'Email ' : 'SMS '}
                            {ucCategory.charAt(0) + ucCategory.substr(1).toLowerCase()}
                        </span>
                    </div>
                )}
                {ucCategory === 'SCHEDULED' && this.props.messageObject.scheduleTime !== null && (
                    <div className={classnames('label label-info', style.noteLabel)}>
                        <span>Scheduled</span>
                    </div>
                )}
                {ucCategory === 'COMPLETED' && (
                    <div className={classnames('label label-success', style.noteLabel)}>
                        <span>Past</span>
                    </div>
                )}
            </>
        );
    };

    // list of recipients
    _renderRecipientList = type => {
        const {
            messageObject: { emailMsgObj, textMsgObj },
        } = this.props;
        const isEmail = type === 'email';

        const obj = isEmail ? emailMsgObj : textMsgObj;
        if (obj.settings.recipientsType.toUpperCase() === 'RESPONDENT') {
            return (
                <ul className={style.recipients}>
                    <li className="label label-warning">Respondent(s)</li>
                </ul>
            );
        }

        return (
            <ul className={style.recipients}>
                {_map(obj.recipients, recipient => {
                    return (
                        <li key={recipient} className="label label-default">
                            {recipient}
                        </li>
                    );
                })}
            </ul>
        );
    };

    render() {
        const {
            messageObject: { emailMsgObj, textMsgObj },
            sendDateStr,
        } = this.props;

        // RETURN - email

        return (
            <>
                {' '}
                <div className={classnames(style.messageViewerWrapper, style.email)}>
                    {this._renderInfoArea('email')}

                    <div className={style.sendArea}>
                        <div>
                            <label>To:</label>
                            {this._renderRecipientList('email')}
                        </div>

                        {sendDateStr !== '' && (
                            <div>
                                <label>
                                    {['DRAFT', 'SCHEDULED'].includes(
                                        emailMsgObj.category.toUpperCase()
                                    )
                                        ? 'Send On:'
                                        : 'Sent On:'}
                                </label>
                                <div>
                                    {sendDateStr.length <= 1
                                        ? `(${conditionTranslator(emailMsgObj.condition)})`
                                        : sendDateStr}
                                </div>
                            </div>
                        )}

                        <div>
                            <label>Subject:</label>
                            <div>{emailMsgObj.messageInfo.subject}</div>
                        </div>
                    </div>

                    <div className={style.messageArea}>
                        {this.showLines(emailMsgObj.messageInfo.body)}

                        {emailMsgObj.messageInfo.actionUrl && emailMsgObj.messageInfo.actionText && (
                            <div className={style.button}>
                                <a
                                    className="btn btn-lg btn-success"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={emailMsgObj.messageInfo.actionUrl}
                                >
                                    {emailMsgObj.messageInfo.actionText}
                                </a>
                            </div>
                        )}
                        {emailMsgObj.messageInfo.actionUrl && (
                            <div>
                                <label>{emailMsgObj.messageInfo.actionText} URL:&nbsp;&nbsp;</label>
                                <a
                                    className="text-primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={emailMsgObj.messageInfo.actionUrl}
                                >
                                    {emailMsgObj.messageInfo.actionUrl}
                                </a>
                            </div>
                        )}

                        <br />

                        {this.showLines(emailMsgObj.messageInfo.outroLines)}
                    </div>
                </div>
                <br />
                <div className={classnames(style.messageViewerWrapper, style.sms)}>
                    {this._renderInfoArea('sms')}

                    <div className={style.sendArea}>
                        <div>{this._renderRecipientList('sms')}</div>
                    </div>

                    <div className={style.messageArea}>
                        {sendDateStr !== '' && sendDateStr.length <= 1 ? (
                            <p>({conditionTranslator(textMsgObj.condition)})</p>
                        ) : (
                            <p>{sendDateStr}</p>
                        )}
                        <div>{textMsgObj.messageInfo.body}</div>
                    </div>
                </div>{' '}
            </>
        );
    }
}
